import React, { useState, useEffect, useCallback } from 'react';
import socketIOClient from 'socket.io-client';
import { Typewriter } from 'react-simple-typewriter'
import './Game.css';
import VoteDisplay from '../VoteDisplay/VoteDisplay';
// import storyScene from './storyScene.jpeg';
import fallback_image from './fallback_square.webp';


// env for endpoints
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const IMAGE_BASE_URL = 'https://istory-images.wireframed.org';
// const fallback_image = `${IMAGE_BASE_URL}/timeglass.webp`;

function Game() {
  const [currentNode, setCurrentNode] = useState(null);
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [loadState, setLoadState] = useState('waiting'); // Controls the animation sequence
  const [showGetReady, setShowGetReady] = useState(false); // New state to control the display of "Get ready to vote"
  const [countIn, setCountIn] = useState(false); // To trigger count-in display
  const [countDownValue, setCountDownValue] = useState(0); // To store the countdown value
  const [contentVisible, setContentVisible] = useState(false); // false by default
  const [typewriterKey, setTypewriterKey] = useState(0); // Initialize a state to track Typewriter key
  const [imageKey, setImageKey] = useState(0); // Initialize a state to track image re-renders if necessary
  const [typewriterDelayPassed, setTypewriterDelayPassed] = useState(false); // New state for typewriter delay
  const [voteDisplayVisible, setVoteDisplayVisible] = useState(false); // New state for controlling VoteDisplay visibility
  const [voteDisplayKey, setVoteDisplayKey] = useState(0); // Initialize a state to track VoteDisplay key
  const [showTheEnd, setShowTheEnd] = useState(false);


  // This function is responsible for updating the UI based on the new data
  // This function handles all the UI updates that need to be re-rendered or reset
  const updateUIComponents = () => {
    setContentVisible(false);
    setTypewriterKey(prevKey => prevKey + 1);
    setImageKey(prevKey => prevKey + 1);
    setVoteDisplayVisible(false);
    setVoteDisplayKey(prevKey => prevKey + 1);
    setContentVisible(false);
  };

  const updateStoryData = useCallback((responseData) => {
    setContentVisible(false);
    setCurrentNode(responseData.data);
    setCurrentNodeId(responseData.currentNodeId);
    updateUIComponents();
  }, []); // Add dependencies here if there are any that updateStoryData needs

  // Function to fetch game data
  const fetchStoryData = useCallback(async () => {
    try {
      const response = await fetch(`${ENDPOINT}/api/story/current`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      console.log('Data fetched');
      setContentVisible(false);
      updateStoryData(responseData);
    } catch (error) {
      console.error("Failed to fetch story data:", error);
    }
  }, [updateStoryData]);

  // Function to make animations wait until the image is fully loaded
  const handleImageLoad = () => {
    // setContentVisible(false);
    // setContentVisible(true);

    // // setLoadState('animateImage');
    // // setContentVisible(true);

    setTimeout(() => {
      setLoadState('animateImage');
      setContentVisible(true);

      setTimeout(() => {
        setTypewriterDelayPassed(true); // Enable typewriter effect after delay
      }, 3200); // Adjust the delay time (2000 ms = 2 seconds) as needed
      
    }, 30);
  };



  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on('update-game', () => {
      setContentVisible(false);
      setVoteDisplayVisible(false);
      setShowTheEnd(false);
      setTypewriterDelayPassed(false);
      setTimeout(() => {
        fetchStoryData();
      }, 200);
    });

    // Call fetchStoryData initially to load data

    fetchStoryData();

    // Listener for 'get-ready' event
    socket.on('get-ready', () => {
      setShowGetReady(true); // Display "Get ready to vote"
      setCountIn(false); // Ensure countIn is false until countdown starts
    });

    // Listener for 'countdown' event
    socket.on('countdown', (countdown) => {
      setCountDownValue(countdown); // Update countdown value to display
    
      // Show "Get ready to vote" and then start the countdown
      if (countdown === 3) {
        setShowGetReady(false); // Hide "Get ready to vote" when countdown starts
        setCountIn(true); // Begin displaying countdown numbers
        // setTimeout(() => {
        //   setCountIn(false); // Hide countdown overlay
        // }, 4000);
      }
    
      // Ensures the overlay is hidden once the countdown is below 0
      // if (countdown === 0) {
      //   setTimeout(() => {
      //     setCountIn(false); // Hide countdown overlay
      //   }, 2000);
      // }

    });

    socket.on('react-poll-start', () => {
      setTimeout(() => {
        setCountIn(false); // Hide countdown overlay
      }, 1000);
    });

    // Cleanup on component unmount
    return () => {
      socket.disconnect();
    };
  }, [fetchStoryData]);

  // Button animation trigger and delay
  // useEffect(() => {
  //   if (currentNode && loadState === 'animateImage') { // Check conditions
  //     const delay = (currentNode.text.length * 60) + 4350;
  //     const timer = setTimeout(() => {
  //       setLoadState('animateButtons');
  //     }, delay);

  //     return () => clearTimeout(timer); // Cleanup on component unmount or dependencies change
  //   }
  // }, [currentNode, loadState]);

  useEffect(() => {
    // Ensure voteDisplayVisible becomes true only after the specified delay
    if (currentNode && contentVisible) { 
      const delay = (currentNode.text.length * 60) + 5150;
      // Clear any existing timeout to prevent multiple invocations
      const timeoutId = setTimeout(() => {
        setVoteDisplayVisible(true);
        setShowTheEnd(true);
      }, delay);
  
      // Clear the timeout if the dependencies change before the timeout completes
      return () => clearTimeout(timeoutId);
    }
  }, [currentNode, contentVisible, voteDisplayKey]); // Add voteDisplayKey as a dependency

  // Function to handle new data fetching as requested from VoteDisplay
  const handleFetchNewData = () => {
    setContentVisible(false);
    setVoteDisplayVisible(false);
    setShowTheEnd(false);
    setTypewriterDelayPassed(false);

    setTimeout(() => {
      // fetchStoryData();
    }, 5);
  };

  // Render the game component with the current game data
  return (
    <div className="game">
      <div className="container">



        <div className="wrapper">
          {currentNode && (
            <>

              <div id="image" className={contentVisible ? "visible" : "hidden"}>
                <img 
                  key={imageKey} // This is generally not needed unless you're facing caching issues, but included here for completeness
                  src={`${IMAGE_BASE_URL}/${currentNodeId}.webp`}
                  // src={`${currentNode.image_path}`}
                  alt="Story Scene" 
                  onLoad={() => {
                    handleImageLoad();
                    console.log('Image loaded');
                  }}
                  onError={(e) => {
                    e.target.src = fallback_image;
                  }}
                  style={{ 
                    animation: loadState === 'animateImage' ? `fadeInAnimationImage 2.5s ease-out forwards` : 'none'
                  }}
                />
              </div>


              <div id="text" className={contentVisible ? "visible" : "hidden"}>
                {typewriterDelayPassed && ( // Only render Typewriter after delay
                  <Typewriter
                    key={typewriterKey} // Use the state as a key
                    words={[currentNode.text]}
                    loop={1}
                    typeSpeed={60}
                    delaySpeed={60}
                  />
                )}
              </div>
              

              {currentNode.type === "choice" && (

                // PROD:
                <div className="vote-and-countdown" style={{opacity: voteDisplayVisible ? 1 : 0, animation: voteDisplayVisible ? 'fadeIn 2s ease-out forwards' : 'none'}}>
                 <VoteDisplay key={voteDisplayKey} onFetchNewData={handleFetchNewData} />
                </div>

                // DEV:
                // <div className="vote-and-countdown">
                //   <VoteDisplay key={voteDisplayKey} onFetchNewData={handleFetchNewData} />
                // </div>

              )}

              {currentNode.type === "ending" && (
                <h3 className="fadeIn" id="the-end" style={{opacity: showTheEnd ? 1 : 0, animation: showTheEnd ? 'fadeIn 2s ease-out forwards' : 'none' }}>
                {/* // <h3 className="fadeIn" id="the-end" style={{opacity: showTheEnd ? 1 : 0, animation: 'fadeIn 2s ease-out forwards' }}> */}
                  Slutt
                </h3>
              )}

              {currentNode.type === "lore" && (
                <h3 className="fadeIn" id="continue" style={{opacity: showTheEnd ? 1 : 0, animation: showTheEnd ? 'fadeIn 2s ease-out forwards' : 'none' }}>
                {/* // <h3 className="fadeIn" id="the-end" style={{opacity: showTheEnd ? 1 : 0, animation: 'fadeIn 2s ease-out forwards' }}> */}
                  Historien fortsetter
                </h3>
              )}

              </>
          )}

        </div>
      </div>
                  {/* Overlay that becomes active during count-in or "Get ready to vote" */}
                  {(showGetReady || countIn) && (
              <div className="overlay">
                {showGetReady && <h2 className="get-ready">Avstemning om</h2>}
                {countIn && <h2 className="get-ready">{countDownValue > 0 ? countDownValue : 'Stem!'}</h2>}
              </div>
            )}
    </div>

    
  );
}

export default Game;